export default {
    global: {
        use_btn: '立即使用',
        start_use: '开始使用',
        free_btn: '免费试用',
        open_btn: '立即开通',
        enter_btn: '进入工作台',
        outLog_btn: '退出',
        login_btn: '注册/登录',
        tips: '提示',
        outlog_tips: '此操作将退出当前登录账号, 是否继续?',
        sure_btn: '确定',
        cancel: '取消',
        agreement_vip: 'ChatExcel会员协议'


    },
    //header
    header_config: {
        product: {
            title: '产品',
            content: [{
                    card_tit: '酷表Chat Excel',
                    card_cont: '仅通过聊天，就最控制Excel表格'
                },
                {
                    card_tit: 'AI VOC',
                    card_cont: '借助AI挖掘数据，对话式获取产品/消费者/市场洞察'
                },
                {
                    card_tit: 'AI 智能客服',
                    card_cont: '全渠道、全流程覆盖，提供智能化、个性化服务'
                }
            ]
        },
        price: '价格',
        tutorials: '免费教程',
        openPlatform: '开放平台'
    },
    // footer
    footer_config: {
        information: '扫码添加客服微信，关注公众号',
        about_us: '关于我们',
        introduce: '公司介绍',
        update_dynamic: '更新动态',
        update_log: '更新日志'
    },
    // 主页
    startPage: {
        top_title: {
            title: '仅通过聊天',
            title1: 'AI即可',
            title_color: '处理Excel',
            title_and: '和',
            title_color_2: '数据分析',
            top_speak: '您来说,ChatExcel来做',
            illustrate: '全球2000W+用户的选择',
            top_gif: 'https://qiniu.yuankongai.com/20240927-202850.gif'
        },
        center_config: {
            tit1: 'ChatExcel以',
            tit_color: '聊天的方式',
            tit2: '自动处理表格'
        },
        tabs_data: [
            { id: '0', title: '一句话，搞定400+函数', content: 'ChatExcel可以替代函数处理，为用户提供更智能的数据处理服务。' },
            { id: '1', title: '数据可视化，多种图表展现', content: '数据可视化，近10种图表展现，折线图、柱状图、条形图、饼图、面积图等。' },
            { id: '2', title: '智能辅助，高性能数据分析', content: 'ChatExcel可进行多表分析、交叉对比等多种分析，能提供数据感知建议，自动纠错。' },
        ],
        area_data: [
            { id: '0', title: 'Section 1 Title', img_url: 'answer1' },
            { id: '1', title: 'Section 2 Title', img_url: 'answer2' },
            { id: '2', title: 'Section 3 Title', img_url: 'answer3' },
        ],
        price_config: {
            tit1: '酷表ChatExcel',
            tit_color: '定价',
            price_p1: '限时',
            price_p2: '，',
            price_p3: '会员时长',
            price_p4: '',
            price_color1: '特惠活动',
            price_color2: '翻倍送',
            priceList: [{
                    id: 1,
                    title: '免费试用',
                    price: '0',
                    priceold: '每天免费用',
                    symbol: '¥',
                    date: '',
                },
                // {
                //     id: 2,
                //     title: '终身 VIP',
                //     price: '199',
                //     priceold: '原价 ¥999',
                //     symbol: '¥',
                //     date: '',
                // },
                {
                    id: 2,
                    title: '买2年送12个月',
                    price: '159/',
                    priceold: '原价 ¥696/2年',
                    symbol: '¥',
                    date: '3年',
                },
                {
                    id: 3,
                    title: '一年',
                    price: '99/',
                    priceold: '原价 ¥348/年',
                    symbol: '¥',
                    date: '年',
                },
                {
                    id: 4,
                    title: '1个月',
                    price: '25.9/',
                    priceold: '原价 ¥29/月',
                    symbol: '¥',
                    date: '月',
                }
            ],
        },
        recommend_config: {
            tit: '他们都在推荐酷表',
            tit_color: 'ChatExcel',
            commentList: [{
                    avatar: 'avatar1',
                    name: "职场老鸟",
                    comment: "酷表ChatExcel让人眼前一亮，简直是职场人的福音啊，省时省力省心～",
                },
                {
                    avatar: 'avatar2',
                    name: "行政宫先生",
                    comment: "你们开发ChatExcel这个网站，非常的酷，以自然语言描述需求，机器进行表格操作，非常适合我们整个行业需求。",
                },
                {
                    avatar: 'avatar3',
                    name: "互联网公司经理",
                    comment: "想说只有四个字，感谢你们。感谢你们辛苦的付出，为我们仍在一线辛苦的表哥汇总而努力，期待早日解放。",
                },
                {
                    avatar: 'avatar4',
                    name: "北京某知名中学王老师",
                    comment: "酷表ChatExcel ，你输入一个句子，它就会自动运行，给我想要的结果，对于日常工作中的表格，处理起来更是效果惊人实在是太棒了！",
                },
                {
                    avatar: 'avatar5',
                    name: "市场负责人",
                    comment: "工具的价值就是提高效率，提升体验，酷表ChatExcel大大降低了使用Excel的门槛，让我们这些不擅长Excel的人也可能快速准确的进行数据处理。",
                },
                {
                    avatar: 'avatar6',
                    name: "小学教师",
                    comment: "梳理各种成绩的表格都可以在无需函数的情况下进行简单运算和数据整理。学生多，考试多，但是老师的法宝也不少～",
                },
                {
                    avatar: 'avatar7',
                    name: "客服代表",
                    comment: "新手用起来零难度，对于客服而言，对话形式处理表格更加便捷啦。",
                },
                {
                    avatar: 'avatar8',
                    name: "人力资源专员",
                    comment: "刚入职没多久，因为欠缺excel的技巧，在整理员工信息的表格中很吃力。但是用chatexcel不需要技术背景也可以高效整合信息。",
                },
                {
                    avatar: 'avatar9',
                    name: "数据录入员",
                    comment: "ChatExcel可以同时删减批量单元格内容，这种自动化功能大大减少了我的工作量，早点下班！",
                },
                {
                    avatar: 'avatar10',
                    name: "大学生",
                    comment: "特种兵式的旅游规划和预算管理用简单几句话就能实现，不愧是我的特种兵搭子！而且重点是免费免费免费！这羊毛薅得真香。",
                },
                {
                    avatar: 'avatar11',
                    name: "口腔医疗管理员",
                    comment: "原来都是用 command+F 来查找特定患者信息，一个一个点进去删改，用chatexcel一句话全能帮我改完～",
                },
            ],
        },


    },
    // 选择套餐弹窗
    priceDialogPage: {
        dialog_title: '选择套餐',
        member_type: '会员类型',
        member_type_list: [{
            name: 'ChatExcel套餐'
        }],
        purchase_duration: '购买时长',
        payment_order: '支付订单',
        actual_payment: '实付',
        VIPExclusive: '现在下单 VIP用户专享100M带宽和独享算力通道',
        pay_type: '支付方式',
        agreement: '支付即视为你同意',
        original_price: '原价',
        priceold_unit: '元',
        symbol: '¥',
    },
    // 登录弹窗
    loginDialogPage: {
        type_phone_or_email: '手机号/邮箱登录',
        type_email: '邮箱登录',
        type_psd: '密码登录',
        phoneoremail: '手机号/邮箱',
        phoneoremail_placeholder: '请输入手机号/邮箱',
        code: '验证码',
        inside_code: '内部验证码',
        code_placeholder: '请输入验证码',
        send_code: '发送验证码',
        repeat: '秒后重发',
        account_number: '账号',
        psd: '密码',
        psd_placeholder: '请输入密码',
        remember: '记住密码',
        forget_psd: '忘记密码',
        loginOrRegister: '登录/注册',
        login: '登录',
        register_tips: '未注册手机号/邮箱验证后将自动登录',
        confirmation_agreement: {
            text1: '登录即表示同意',
            text2: '和',
            agreement1: '用户协议',
            agreement2: '个人信息保护政策'
        },
        reset: {
            back: '返回',
            title: '',
            tit_cont: '',
            email_label: '',
            email_label_placeholder: '',
            btn: '',
            sending: '',
            sent_to: '',
            code_label: '',
            next: '',
            tit_reset: '',
            newpsd: '',
            configpsd: ''
        }
    }
}